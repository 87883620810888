import styled from 'styled-components';

import RecordingControl from 'components/ui/RecordingControl';

export const StoryFinishRecordingControl = styled(RecordingControl)`
  margin-top: 50px;
  justify-content: center;
`;

export const LessonPull = styled.div`
  display: flex;
  gap: 40px;
`;

import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import Audio from 'components/ui/Audio';
import Button from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import Input from 'components/ui/Input';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const EnterName = () => {
  const { updateState } = useUserLSState();
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const isButtonDisabled = useMemo(() => name.trim().length < 3 || name.trim().length > 256, [name]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isButtonDisabled) return;

    updateState('name', name.trim());
    navigate(URLS.selectAgeRange);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <ContentCentered>
      <Audio localSrc="questions/q1.mp3" />
      <Styled.EnterName onSubmit={handleSubmit}>
        <Text variant="lg" colorType="light">
          What is your name?
        </Text>
        <Input className="EnterName__Input" placeholder="My name is..." value={name} onChange={handleChange} />
        <Button type="submit" disabled={isButtonDisabled}>
          Continue
        </Button>
      </Styled.EnterName>
    </ContentCentered>
  );
};

export default EnterName;

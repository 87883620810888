import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateList } from 'react-use';

import renderSwitch from 'lib/renderSwitch';

import useGetNextStory, { NextStoryResponse } from 'api/useGetNextStoryGoLive';

import useAudioTextControls from 'hooks/useAudioTextControls';
import { StatusMessages } from 'hooks/useMediaRecorder';

import Audio from 'components/ui/Audio';
import type { AudioRef } from 'components/ui/Audio';
import { IconButton } from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import ProgressBar from 'components/ui/ProgressBar';
import RecordingControl, { PlaybackStatus, RecordingControlProps } from 'components/ui/RecordingControl';

import { URLS } from 'components/App';
import AudioTextCard from 'components/AudioTextCard';
import Instruction from 'components/Instruction';

import { AGAIN_ICON, ARROW_RIGHT_ICON, CHECK_ICON } from 'assets/icons';
import letterSvgSprite from 'assets/icons/letter.svg';
import speedSvgSprite from 'assets/icons/speed.svg';

import * as Styled from './styles';
import { useHandleRecording } from './useHandleRecording';
import useRecordingStore from './useRecordingStore';

const NEXT_BUTTON_DELAY_MS = 2000;

const RECORDING_CONTROL_ATTRIBUTES: Partial<Record<StatusMessages | PlaybackStatus, RecordingControlProps>> = {
  idle: { text: 'Click and start reading', title: 'Start Recording' },
  recording: { title: 'Stop Recording' },
  playback_playing: { title: 'Pause playback' },
};

type StoryContentProps = Pick<NextStoryResponse, 'sentences' | 'storyId'>;

const StoryContent = ({ sentences, storyId }: StoryContentProps) => {
  const navigate = useNavigate();
  const { state, next, currentIndex } = useStateList(sentences);
  const { audioSpeedStateManager, textSizeStateManager } = useAudioTextControls();
  const recordingManager = useHandleRecording(Number(state.sentenceId), currentIndex, state.text);
  const setStoryId = useRecordingStore((state) => state.setStoryId);

  const instructionAudioRef = useRef<AudioRef>(null);

  const isLastSentence = currentIndex === sentences.length - 1;
  const actionBtnClassname = 'Navigation__Action '.concat(
    recordingManager.isActionsVisible ? 'Navigation__Action--visible' : 'Navigation__Action--hidden'
  );

  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false)

  const [shouldStayOnPage, setShouldStayOnPage] = useState<boolean>(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shouldRedirectQuery = params.get('shouldRedirect');

  useEffect(() => {
    if (shouldRedirectQuery === 'true' && !shouldStayOnPage) {
      navigate(URLS.story)
    }
  }, [shouldRedirectQuery, shouldStayOnPage, navigate])

  useEffect(() => {
    const message = 'You have unsaved changes';
    function handleBeforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
      e.returnValue = message;
      setShouldStayOnPage(true);

      navigate(`${URLS.story}?shouldRedirect=true`, {
        replace: true,
      })

      return message
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [navigate, setShouldStayOnPage])

  useEffect(() => {
    setIsNextButtonDisabled(true)
    const timeoutId = setTimeout(() => {
      setIsNextButtonDisabled(false)
    }, NEXT_BUTTON_DELAY_MS)

    return () => {
      clearTimeout(timeoutId)
    }

  }, [currentIndex])

  const goToNextStep = () => {
    recordingManager.resetRecordingStatus();
    if (isLastSentence) {
      setStoryId(storyId);
      navigate(`${URLS.storyFinish}?storyId=${storyId}`);
    } else {
      next();
    }
  };

  return (
    <ContentCentered gradientCirclePosition="center">
      <Styled.Story>
        <Styled.Controls>
          <IconButton
            title="Change font size"
            variant="control"
            xlinkHref={letterSvgSprite + '#letter-' + textSizeStateManager.state.id}
            svgProps={{ width: 32, height: 32 }}
            onClick={textSizeStateManager.next}
          >
            {textSizeStateManager.state.text}
          </IconButton>
          <IconButton
            title="Change reading speed"
            variant="control"
            xlinkHref={speedSvgSprite + '#speed-' + audioSpeedStateManager.state.id}
            svgProps={{ width: 32, height: 32 }}
            onClick={audioSpeedStateManager.next}
          >
            {audioSpeedStateManager.state.text}
          </IconButton>
        </Styled.Controls>
        <Instruction
          audio="story_instruction/s1.mp3"
          audioRef={instructionAudioRef}
          text="Click the box to hear the sentence or click on a word to hear it"
        />
        <AudioTextCard
          audio={state.audioS3Path ?? ''}
          audioSpeed={audioSpeedStateManager.state.value}
          playingAudioRef={instructionAudioRef}
          fontSize={textSizeStateManager.state.value}
          text={state.text}
          highlights={state.highlights}
          details={
            <ProgressBar className="Story__ProgressBar" currentStep={currentIndex + 1} totalSteps={sentences.length} />
          }
          className="Story__AudioTextCard"
        />
        <Styled.Navigation>
          <IconButton
            className={actionBtnClassname}
            disabled={recordingManager.isRecordAgainDisabled}
            variant="action"
            icon={AGAIN_ICON}
            onClick={recordingManager.restartRecording}
          >
            Record Again
          </IconButton>
          {recordingManager.isAudioAvailable && <Audio {...recordingManager.audioProps} />}
          <RecordingControl
            status={recordingManager.controlStatus}
            onClick={recordingManager.onRecordControlClick}
            {...RECORDING_CONTROL_ATTRIBUTES[recordingManager.controlStatus ?? 'idle']}
          />
          <IconButton
            className={actionBtnClassname}
            variant="action"
            icon={isLastSentence ? CHECK_ICON : ARROW_RIGHT_ICON}
            onClick={goToNextStep}
            loading={isNextButtonDisabled}
            disabled={isNextButtonDisabled}
          >
            {isLastSentence ? 'Finish' : 'Next'}
          </IconButton>
        </Styled.Navigation>
      </Styled.Story>
    </ContentCentered>
  );
};

const Story = () => {
  // const { data: storyResponse, isFetching, isError, refetch } = useGetStory();
  const { data: storyData, isFetching, isError, refetch } = useGetNextStory();

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this story" />
      ),
    },
    default: {
      condition: !!storyData?.storyId,
      render: <StoryContent sentences={storyData?.sentences ?? []} storyId={storyData?.storyId!} />,
    },
  });
  return <>{renderState}</>;
};

export default Story;

import axios from 'axios';

// TODO: hardcoded user_id for MVP
export const USER_ID = '6438285de9c5cbc65bce1685';
export const TREE_ID = '643ce303e9118d7492faa82a';

const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

const token = getCookie('accessToken');

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default apiClient;

export const apiClientSoundOutWord = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SOUND_OUT_WORD,
  responseType: 'blob',
});

export const apiLmsClient = axios.create({
  baseURL: process.env.REACT_APP_LMS_API_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const apiGoLiveClient = axios.create({
  baseURL: process.env.REACT_APP_NEW_API_URL,
  headers: {
    token: `${token}`,
  },
});

import { useMutation } from '@tanstack/react-query';

import { apiLmsClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

const interestsCollected = async () => {
  const { assignmentId } = getAssignmentInfoFromStorage();
  const res = await apiLmsClient.patch(`/assignment/interests_collected/${assignmentId}/`);
  return res.data;
};

const useInterestCollected = () => useMutation(interestsCollected);

export default useInterestCollected;

import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { AssignmentEntity } from 'types';

// todo add real use and assignment id
const getAssignmentInfo = async () => {
  const { assignmentId, userId } = getAssignmentInfoFromStorage();
  const res = await apiGoLiveClient.get<AssignmentEntity>(`/users/${userId}/assignments/${assignmentId}`);
  return camelCaseKeys(res.data, { deep: true });
};

const useGetAssignmentInfo = () =>
  useQuery(['assignmentInfo'], getAssignmentInfo, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 30000,
  });

export type AssignmentResponse = CamelCaseKeys<AssignmentEntity, true>;
export default useGetAssignmentInfo;

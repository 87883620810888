import { MouseEvent, RefObject, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateList } from 'react-use';

import renderSwitch from 'lib/renderSwitch';
import { getWordsFromText, guessPlaybackTime, isClickFromRelatedElement, parseHighlights } from 'lib/utils';

import useGetNextLesson, { NextLessonResponse } from 'api/useGetNextLessonGoLive';

import Audio, { AudioProps } from 'components/ui/Audio';
import type { AudioRef } from 'components/ui/Audio';
import IconButton from 'components/ui/Button/IconButton';
import Card from 'components/ui/Card';
import ContentLesson from 'components/ui/ContentLesson';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader';
import Sentence from 'components/ui/Sentence';
import Word from 'components/ui/Word';

import { URLS } from 'components/App';
import Instruction from 'components/Instruction';

import { ARROW_LEFT_ICON, ARROW_RIGHT_ICON, CHECK_ICON } from 'assets/icons';

type LessonSentenceContentProps = Pick<NextLessonResponse, 'lessonStory'>;

const LessonSentenceCard = ({
  lessonStory,
  instructionAudioRef,
}: LessonSentenceContentProps & { instructionAudioRef: RefObject<AudioRef> }) => {
  const navigate = useNavigate();
  const audioRef = useRef<AudioRef>(null);
  const [currentPlayingAudioIndex, setCurrentPlayingAudioIndex] = useState<number | null>(null);
  const [isAudioPlaying, setIsAudionPlaying] = useState(false);
  // state for indices (words) which already highlighted
  const [highlightedIndices, setHighlightedIndices] = useState<Array<number>>([]);
  // state for indices (words) which should be highlighted now
  const [currentHighlightedIndices, setCurrentHighlightedIndices] = useState<Array<number>>([]);
  const wordsBySentences = useMemo(
    () =>
      lessonStory.map(({ text }, lessonStoryIdx) =>
        getWordsFromText(text).map((word, idx) => ({
          lessonStoryIdx,
          idx,
          word,
        }))
      ),
    [lessonStory]
  );
  const { state, next, prev, currentIndex } = useStateList(wordsBySentences);

  const playAudio = (event: MouseEvent<HTMLDivElement>) => {
    // AudioBox logic duplication
    if (isClickFromRelatedElement(event.target as HTMLElement, 'tooltip')) return;

    instructionAudioRef.current?.stop();
    setIsAudionPlaying(true);
    setCurrentPlayingAudioIndex(currentIndex);
    audioRef.current?.setSrc(lessonStory[currentIndex].audioS3Path);
    audioRef.current?.play();
  };

  /*
   * TODO:
   * Temporary solution to play sentences audio one by one and highlighted its words.
   * Needs refactor and logic change when sentences will be handled separately, not like paragraph.
   */
  const handleAudioStateUpdate: AudioProps['onStateUpdate'] = (state, audioEl) => {
    if (currentPlayingAudioIndex !== null) {
      const latestCurrentTime = audioEl.currentTime;

      const currentHighlights = parseHighlights(
        lessonStory[currentPlayingAudioIndex].highlights,
        latestCurrentTime,
        highlightedIndices
      );
      setCurrentHighlightedIndices(currentHighlights);
      setHighlightedIndices((prev) => prev.concat(currentHighlights));
    }

    const currentTime = guessPlaybackTime(state, audioEl);

    // handle end of audio
    if (!state.buffered.length || state.duration !== currentTime) {
      setIsAudionPlaying(false);
      return;
    }

    // clear highlights
    setHighlightedIndices([]);
    setCurrentHighlightedIndices([]);

    // setting next audio index to highlight words depends on nextSentence
    // setCurrentPlayingAudioIndex(nextSentence ? currentPlayingAudioIndex! + 1 : null);
    // setting new src for audio depends on nextSentence
    // audioRef.current?.setSrc(nextSentence ? nextSentence.audioS3Path : '');
    // rewind audio or stop it depends on nextSentence
    // nextSentence ? audioRef.current?.seek(0) : audioRef.current?.stop();
    // setIsAudionPlaying state to enable audio box if there are no nextSentence
  };
  const isLastSentence = currentIndex === lessonStory.length - 1;
  const isFirstSentence = currentIndex === 0;

  const handleNextSentence = () => {
    if (!isLastSentence) {
      next();
    } else {
      navigate(URLS.lessonWordsSelect);
    }
  };

  const handlePrevSentence = () => {
    if (!isFirstSentence) {
      prev();
    } else {
      navigate(URLS.lessonWords);
    }
  };

  return (
    <>
      <Card
        style={{
          cursor: 'pointer',
          padding: 60,
          minHeight: '300px',
          pointerEvents: isAudioPlaying ? 'none' : 'initial',
        }}
        onClick={playAudio}
      >
        <Audio localSrc="" ignoreAppMute ref={audioRef} onStateUpdate={handleAudioStateUpdate} />
        <Sentence>
          {state.map(({ lessonStoryIdx, idx, word }) => (
            <Word
              key={`${lessonStoryIdx}_${idx}`}
              withTooltip
              withSound
              isHighlighted={lessonStoryIdx === currentPlayingAudioIndex && currentHighlightedIndices.includes(idx)}
            >
              {word}
            </Word>
          ))}
        </Sentence>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
        <IconButton variant="action" icon={ARROW_LEFT_ICON} onClick={handlePrevSentence}>
          Prev
        </IconButton>
        <IconButton variant="action" icon={isLastSentence ? CHECK_ICON : ARROW_RIGHT_ICON} onClick={handleNextSentence}>
          {isLastSentence ? 'Finish' : 'Next'}
        </IconButton>
      </div>
    </>
  );
};

const LessonSentenceContent = ({ lessonStory }: LessonSentenceContentProps) => {
  const instructionAudioRef = useRef<AudioRef>(null);

  return (
    <ContentLesson
      instruction={
        <Instruction
          audioRef={instructionAudioRef}
          text="Click the box to hear the story or click on a word to hear it."
          audio="lesson_instruction/l3.mp3"
        />
      }
    >
      <LessonSentenceCard lessonStory={lessonStory} instructionAudioRef={instructionAudioRef} />
    </ContentLesson>
  );
};

const LessonSentence = () => {
  const { data: lessonData, isFetching, isError, refetch } = useGetNextLesson();

  const renderState = renderSwitch({
    loading: {
      condition: isFetching,
      render: <Loader />,
    },
    error: {
      condition: isError,
      render: (
        <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
      ),
    },
    default: {
      condition: !!lessonData,
      render: <LessonSentenceContent lessonStory={lessonData?.lessonStory ?? []} />,
    },
  });

  return <>{renderState}</>;
};

export default LessonSentence;

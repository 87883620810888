import { useEffect } from 'react';

import { getStoriesAndLessonsStatus } from 'lib/utils';

import useGetAssignmentInfo from 'api/useGetAssinmentInfoGoLive';

import useUserLSState, { initialState } from 'hooks/useUserLSState';

import Button from 'components/ui/Button/Button';
import ContentCentered from 'components/ui/ContentCentered';
import ErrorCard from 'components/ui/ErrorCard';
import Loader from 'components/ui/Loader/Loader';
import MenuCard from 'components/ui/MenuCard';
import Text from 'components/ui/Text/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const MenuContent = () => {
  const { userState, updateState, remove } = useUserLSState();
  const { data: assignmentData, isFetching, isError, refetch } = useGetAssignmentInfo();

  useEffect(() => {
    if (assignmentData) {
      const { isAllLessonComplete, isAllStoriesComplete } = getStoriesAndLessonsStatus(assignmentData);

      updateState('menu', {
        story: isAllStoriesComplete ? 'completed' : 'ready',
        lesson: isAllLessonComplete ? 'completed' : 'ready',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentData]);

  const goBackToLMS = () => {
    remove();
    window.location.href = process.env.REACT_APP_LMS_URL!;
  };

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return (
      <ErrorCard style={{ marginTop: 60 }} onRetry={refetch} message="There was a problem retrieving this lesson" />
    );
  }

  return (
    <>
      {userState?.menu?.story === 'completed' && userState?.menu?.lesson === 'completed' ? (
        <ContentCentered>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <Text colorType="light" variant="lg">
              You have completed the assignment
            </Text>
            <Button onClick={goBackToLMS}>Go back to LUCA</Button>
          </div>
        </ContentCentered>
      ) : (
        <ContentCentered gradientCirclePosition="center">
          <Styled.MenuRoot>
            <MenuCard type="story" status={userState?.menu?.story ?? initialState.menu.story} to={URLS.story} />
            <MenuCard
              type="lesson"
              status={userState?.menu?.lesson ?? initialState.menu.lesson}
              to={URLS.lessonSound}
            />
          </Styled.MenuRoot>
        </ContentCentered>
      )}
    </>
  );
};

export default MenuContent;

import { useMutation } from '@tanstack/react-query';
import { CamelCaseKeys } from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { StoryCompleteGoLiveEntity } from 'types';

const storyComplete = async (payload: StoryCompleteGoLivePayload) => {
  const data = getAssignmentInfoFromStorage();
  const res = await apiGoLiveClient.post(
    `/users/${data.userId}/assignments/${data.assignmentId}/story_complete`,
    snakeCaseKeys(payload, { deep: true })
  );
  return res.data;
};
const useStoryCompleteGoLive = () => useMutation(storyComplete);
export type StoryCompleteGoLivePayload = CamelCaseKeys<StoryCompleteGoLiveEntity, true>;
export default useStoryCompleteGoLive;

import { useRef, useState } from 'react';
import { useBoolean } from 'react-use';

import useMuteAudio from 'hooks/useMuteAudio';

import PhraseDialog from 'components/PhraseDialog';

import * as Styled from './styles';

type Phrase = {
  text: string;
  id: string | number;
  audio: string;
};

type Phrases = Phrase[];

type FeedProps = {
  phrases: Phrases;
  onFeedFull?: () => void;
  children?: React.ReactNode;
};

const Feed = ({ phrases, onFeedFull, children }: FeedProps) => {
  const { audioMuted } = useMuteAudio();
  const [clouds, setClouds] = useState<Phrases>([phrases[0]]);
  const [isChildrenVisible, setIsChildrenVisible] = useBoolean(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const addNextCloud = () => {
    const nextCloud = phrases[clouds.length];

    if (nextCloud) {
      setClouds((prev: Phrases) => prev.concat(nextCloud));
    } else {
      !!children ? setIsChildrenVisible(true) : onFeedFull?.();
    }
  };

  const scrollToBottom = () => {
    const anchor = anchorRef.current;
    if (!anchor) return;
    setTimeout(() => anchor.scrollIntoView({ behavior: 'smooth', block: 'end' }), 300);
  };

  const handleAnimationEnd = () => onFeedFull?.();

  return (
    <Styled.Feed>
      {clouds.map(({ id, audio, text }) => (
        <PhraseDialog
          className="Feed__Dialog"
          key={id}
          audioMuted={audioMuted}
          onActionEnd={addNextCloud}
          audio={audio}
          text={text}
        />
      ))}
      {isChildrenVisible && !!children && (
        <Styled.AnimatedContainer onAnimationStart={scrollToBottom} onAnimationEnd={handleAnimationEnd}>
          {children}
        </Styled.AnimatedContainer>
      )}
      <div ref={anchorRef} style={{ height: 1 }}></div>
    </Styled.Feed>
  );
};

export default Feed;

import { useMutation } from '@tanstack/react-query';
import snakeCaseKeys from 'snakecase-keys';

import { apiLmsClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { StoryCompleteLMSEntity } from 'types';

const storyComplete = async (payload: StoryCompleteLMSEntity) => {
  const data = getAssignmentInfoFromStorage();
  const res = await apiLmsClient.post(
    `/assignment/story_complete/${data.assignmentId}/`,
    snakeCaseKeys(payload, { deep: true })
  );
  return res.data;
};
const useStoryCompleteLMS = () => useMutation(storyComplete);

export default useStoryCompleteLMS;

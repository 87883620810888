import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { LessonGoLiveEntity } from 'types';

// todo add real user and assignment
const getNextLesson = async () => {
  const { assignmentId, userId } = getAssignmentInfoFromStorage();
  const response = await apiGoLiveClient.get<LessonGoLiveEntity>(
    `/users/${userId}/assignments/${assignmentId}/next_lesson`
  );
  return camelCaseKeys(response.data, { deep: true });
};

const useGetNextLesson = () => useQuery(['story'], getNextLesson);

export type NextLessonResponse = CamelCaseKeys<LessonGoLiveEntity, true>;
export default useGetNextLesson;

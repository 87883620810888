import { useSearchParams } from 'react-router-dom';
import { useLocalStorage, useMount } from 'react-use';

import { MenuItemStatuses } from 'types';

export type UserState = {
  name: string;
  ageRange: string;
  places: string[];
  characters: string[];
  menu: {
    story: MenuItemStatuses;
    lesson: MenuItemStatuses;
  };
  selectedWordsIndexes: string[];
  assignmentId?: string | null;
  userId?: string | null;
};

export const initialState: UserState = {
  name: '',
  ageRange: '',
  places: [],
  characters: [],
  menu: {
    story: 'ready',
    lesson: 'ready',
  },
  selectedWordsIndexes: [],
};

const useUserLSState = () => {
  const [searchParams] = useSearchParams();
  const [userState, setUserState, remove] = useLocalStorage<UserState>('luca:userState', initialState);
  useMount(() => {
    setUserState({
      ...initialState,
      ...userState,
      ...(searchParams.get('assignment_id') ? { assignmentId: searchParams.get('assignment_id') } : {}),
      ...(searchParams.get('reader_id') ? { userId: searchParams.get('reader_id') } : {}),
    });
  });

  const updateState = <TKey extends keyof UserState>(key: TKey, value: UserState[TKey]) =>
    setUserState({ ...userState, [key]: value } as UserState);

  const clearLocalStorage = () => {
    setUserState({ ...userState, ...initialState } as UserState);
  };

  return { userState, updateState, remove, clearLocalStorage } as const;
};

export default useUserLSState;

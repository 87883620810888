import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { StoryGoLiveEntity } from 'types';

const getNextStory = async () => {
  const { assignmentId, userId } = getAssignmentInfoFromStorage();
  const response = await apiGoLiveClient.get<StoryGoLiveEntity>(
    `/users/${userId}/assignments/${assignmentId}/next_story`
  );
  return camelCaseKeys(response.data, { deep: true });
};

const useGetNextStory = () => useQuery(['story'], getNextStory);

export type NextStoryResponse = CamelCaseKeys<StoryGoLiveEntity, true>;
export default useGetNextStory;

import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed/Feed';

import { AGAIN_ICON, THUMB_DOWN_ICON, THUMB_UP_ICON } from 'assets/icons';

import * as Styled from './styles';

const PHRASES = [
  {
    id: 0,
    text: 'Congratulations! You finished the lesson!',
    audio: 'final_screen_audio/final_screen_1.mp3',
  },
  {
    id: 2,
    text: 'Did you like the lesson?',
    audio: '',
  },
];

const LessonFinish = () => {
  const navigate = useNavigate();
  const { clearLocalStorage } = useUserLSState();

  const startAgain = () => {
    clearLocalStorage();
    navigate(URLS.menu);
  };

  return (
    <ContentLuca
      actions={
        <Button iconLeft={AGAIN_ICON} style={{ margin: '0 auto' }} onClick={startAgain}>
          Go to menu
        </Button>
      }
    >
      <Feed phrases={PHRASES}>
        <Styled.LessonPull>
          <Button iconLeft={THUMB_UP_ICON}>Yes</Button>
          <Button iconLeft={THUMB_DOWN_ICON}>No</Button>
        </Styled.LessonPull>
      </Feed>
    </ContentLuca>
  );
};

export default LessonFinish;

import SvgIcon from 'components/ui/SvgIcon';

import { LOGO_ICON } from 'assets/icons';

const Logo = () => {
  return (
    <div>
      <SvgIcon component={LOGO_ICON} />
    </div>
  );
};

export default Logo;

import { useMutation } from '@tanstack/react-query';
import { CamelCaseKeys } from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { InterestEntity } from 'types';

import apiClient from 'lib/apiClient';

const sendInterests = (payload: InterestPayload) =>
  apiClient.post('/interests_v2', snakeCaseKeys(payload, { deep: true }));

const useSendInterests = () => useMutation(sendInterests);

export type InterestPayload = CamelCaseKeys<InterestEntity, true>;
export default useSendInterests;

import { useQuery } from '@tanstack/react-query';
import camelCaseKeys, { CamelCaseKeys } from 'camelcase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { InterestsGoLiveEntity } from 'types';

const getInterests = async () => {
  const { userId } = getAssignmentInfoFromStorage();
  const res = await apiGoLiveClient.get<InterestsResponse>(`/users/${userId}/random_interest_group`);
  return camelCaseKeys(res.data, { deep: true });
};

const useGetInterests = () => useQuery(['getInterests'], getInterests);

export type InterestsResponse = CamelCaseKeys<InterestsGoLiveEntity, true>;
export default useGetInterests;

import styled, { FlattenSimpleInterpolation, css, keyframes } from 'styled-components';

import type { ButtonProps } from './Button';
import type { IconButtonProps } from './IconButton';

export const ButtonRoot = styled.button<Pick<ButtonProps, 'size'>>`
  --bg-color: var(--indigo-500);
  --border-color: var(--indigo-400);
  --shadow-color: var(--indigo-800);
  /* TODO: replace on UI-kit color palette */
  --shadow-outline-color: rgba(20, 16, 71, 0.72);
  --border-width: 5px;
  --focus-ring-color: var(--grey-100);
  --padding-x: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 var(--padding-x);
  height: 64px;
  background-color: var(--bg-color);

  width: ${({ size }) => {
    switch (size) {
      case 'medium':
        return '185px';
      case 'fluid':
        return '100%';
      case 'auto':
        return 'auto';
      default:
        return 'auto';
    }
  }};

  border: var(--border-width) solid var(--border-color);
  border-radius: 65px;
  box-shadow: 0 4px 0 var(--shadow-color);
  filter: drop-shadow(0px 4px 4px var(--shadow-outline-color));
  cursor: pointer;
  text-align: center;

  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
    outline-offset 0.2s ease;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover:not(:disabled) {
    opacity: 0.9;
  }

  :focus-visible {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 8px;
  }

  :active:not(:disabled) {
    transform: translateY(6px);
    box-shadow: none;
    outline-offset: 0px;
  }

  .Button__Text {
    line-height: 1;
  }
`;

const actionButtonStyles = css`
  --bg-color: var(--deep-purple-300);
  --bg-hover-color: var(--deep-purple-a300);
  --bg-active-color: var(--deep-purple-a400);

  border-radius: 50%;
  box-shadow: none;
  width: 80px;
  height: 80px;
  color: var(--grey-100);
  background-position: center;
  transition: background 0.8s, outline-offset 0.2s ease, opacity 0.2s ease-in-out;

  :hover:not(:disabled) {
    background: var(--bg-hover-color) radial-gradient(circle, transparent 1%, var(--bg-hover-color) 1%) center/15000%;
  }

  :active:not(:disabled) {
    transform: none;
    box-shadow: none;
    outline-offset: 0px;
    background-color: var(--bg-active-color);
    background-size: 100%;
    transition: background 0s, outline-offset 0.2s ease, opacity 0.2s ease-in-out;
  }

  :focus-visible:active:not(:disabled) {
    background-color: var(--bg-color);
  }
`;

const controlButtonStyles = css`
  --bg-color: var(--grey-100);

  border-radius: 10px;
  box-shadow: 0px 6px 0px var(--black-transparent-30);
  color: var(--navy-900);

  :hover:not(:disabled) {
    background-color: var(--deep-purple-50);
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const loadingButtonStyles = css`
  animation: ${rotate} 2s linear infinite;
`

const stylesByVariant: Record<NonNullable<IconButtonProps['variant']>, FlattenSimpleInterpolation> = {
  action: actionButtonStyles,
  control: controlButtonStyles,
};

export const IconButtonRoot = styled(ButtonRoot)<Pick<IconButtonProps, 'variant' | 'loading'>>`
  --padding-x: 16px;
  border: none;
  filter: none;

  ${({ variant = 'action' }) => stylesByVariant[variant]}
  ${({ loading = false }) => loading ? loadingButtonStyles : {}}
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-items: center;
  gap: 12px;
`;

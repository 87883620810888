import { memo } from 'react';

import Audio from 'components/ui/Audio';
import Dialog from 'components/ui/Dialog';
import type { DialogProps } from 'components/ui/Dialog';
import Text from 'components/ui/Text';

type PhraseDialogProps = {
  /*
    Pass audioMuted as a prop and use memo because if we will use
    useMuteAudio hook or audioMuted prop will be changed it is possible
    case when we unmute audio during animation handleActionEnd function
    would not be called.
  */
  audioMuted: boolean;
  onActionEnd: () => void;
  audio: string;
  text: string;
} & DialogProps;

const PhraseDialog = ({ audioMuted, onActionEnd, audio, text, ...restProps }: PhraseDialogProps) => {
  const handleQuestionAnimationEnd = () => {
    if (audioMuted || !audio) onActionEnd();
  };

  const handleAudioEnded = () => {
    if (!audioMuted) onActionEnd();
  };

  return (
    <Dialog {...restProps} onAnimationEnd={handleQuestionAnimationEnd}>
      <Audio localSrc={audio} onPause={handleAudioEnded} />
      <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} className="Questions__Text">
        {text}
      </Text>
    </Dialog>
  );
};

export default memo(PhraseDialog, () => true);

import styled from 'styled-components';

export const EnterName = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-bottom: 20px;
  width: 600px;

  .EnterName__Input {
    margin-bottom: 20px;
    width: 100%;
  }
`;

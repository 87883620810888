import styled, { keyframes, css } from 'styled-components';

const fadeInWithShiftBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const dialogAnimation = css`
  ${fadeInWithShiftBottom} 1s ease
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 20px 0 30px;

  .Feed__Dialog {
    width: 80%;
    animation: ${dialogAnimation};
  }
`;

export const AnimatedContainer = styled.div`
  animation: ${dialogAnimation};
`;

import { useMutation } from '@tanstack/react-query';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

// todo add real user and assignment data
const lessonComplete = async () => {
  const { assignmentId, userId } = getAssignmentInfoFromStorage();
  const res = await apiGoLiveClient.post(`/users/${userId}/assignments/${assignmentId}/lesson_complete`);
  return res.data;
};

const useLessonCompleteGoLive = () => useMutation(lessonComplete);

export default useLessonCompleteGoLive;

import { useMutation } from '@tanstack/react-query';
import snakeCaseKeys from 'snakecase-keys';

import { apiLmsClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

// todo add real user and assignment data
//assignment/lesson_complete/{assignment_id}/

const lessonComplete = async (payload: any) => {
  const { assignmentId } = getAssignmentInfoFromStorage();
  const res = await apiLmsClient.post(
    `/assignment/lesson_complete/${assignmentId}/`,
    snakeCaseKeys(payload, { deep: true })
  );
  return res.data;
};

const useLessonCompleteLMS = () => useMutation(lessonComplete);

export default useLessonCompleteLMS;

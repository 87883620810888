import { create } from 'zustand';

import './useHandleRecording';

export type SentenceAudioState = {
  id: number;
  blob: Blob;
  blobUrl: string;
  chunks: Blob[];
  text: string;
};

type SentenceRecordingState = {
  recordings: Record<number, SentenceAudioState>;
  storyId: string;
  setRecordingById: (id: number, value: SentenceAudioState) => void;
  setStoryId: (id: string) => void;
  reset: () => void;
};

const useRecordingStore = create<SentenceRecordingState>()((set) => ({
  recordings: {},
  storyId: '',
  setRecordingById: (id, value) => set((prevState) => ({ recordings: { ...prevState.recordings, [id]: value } })),
  setStoryId: (id) => set(() => ({ storyId: id })),
  reset: () => set({ recordings: {}, storyId: '' }),
}));

export default useRecordingStore;

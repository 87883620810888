export type MediaRecorderLifecycleHooks = Pick<
  MediaRecorder,
  'ondataavailable' | 'onstart' | 'onstop' | 'onerror' | 'onpause' | 'onresume'
>;

export function getUserMedia(constraints?: MediaStreamConstraints): Promise<MediaStream> {
  const notSupportedError = new Error('getUserMedia not supported on your browser!');
  notSupportedError.name = 'NOT_SUPPORTED';
  return navigator?.mediaDevices?.getUserMedia?.(constraints) ?? Promise.reject(notSupportedError);
}

export function createURLfromChunks(chunks: Blob[], options?: BlobPropertyBag) {
  const blob = new Blob(chunks, options);
  const url = URL.createObjectURL(blob);
  return [url, blob] as const;
}

export function checkConstraints(mediaType: MediaTrackConstraints) {
  const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
  const unSupportedConstraints = Object.keys(mediaType).filter(
    (constraint) => !supportedMediaConstraints[constraint as keyof MediaTrackSupportedConstraints]
  );
  if (unSupportedConstraints.length > 0) {
    console.error(`The constraints ${unSupportedConstraints.join(',')} doesn't support on this browser`);
  }
}

export async function audioToBase64(audioFile: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => resolve((e.target?.result ?? '') as string);
    reader.readAsDataURL(audioFile);
  });
}

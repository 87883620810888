import { FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useUserLSState from 'hooks/useUserLSState';

import AgeOption from 'components/ui/AgeOption';
import Audio from 'components/ui/Audio';
import Button from 'components/ui/Button';
import ContentCentered from 'components/ui/ContentCentered';
import FormGroup, { FormControl, FormControlTarget } from 'components/ui/FormGroup';
import Text from 'components/ui/Text';

import { URLS } from 'components/App';

import * as Styled from './styles';

const ageRanges = ['6-8', '9-13', '13+'];

const SelectAgeRange = () => {
  const { userState, updateState } = useUserLSState();
  const [ageRange, setAgeRange] = useState(userState?.ageRange ?? '');
  const navigate = useNavigate();

  const isButtonDisabled = useMemo(() => !ageRange, [ageRange]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isButtonDisabled) return;

    updateState('ageRange', ageRange);
    navigate(URLS.questions);
  };

  const handleChange = (target: FormControlTarget) => {
    setAgeRange(target.value);
  };

  return (
    <ContentCentered>
      <Audio localSrc="questions/q2.mp3" />
      <Styled.SelectAgeRange onSubmit={handleSubmit}>
        <Text variant="lg" colorType="light">
          How old are you?
        </Text>
        <FormGroup className="SelectAgeRange__FormGroup" type="radio" onChange={handleChange}>
          {ageRanges.map((range: string) => (
            <FormControl defaultChecked={range === ageRange} name="ageRange" id={range} value={range} key={range}>
              <AgeOption>{range}</AgeOption>
            </FormControl>
          ))}
        </FormGroup>
        <Button type="submit" disabled={isButtonDisabled}>
          Continue
        </Button>
      </Styled.SelectAgeRange>
    </ContentCentered>
  );
};

export default SelectAgeRange;

import { useRef, useState } from 'react';

import { PlaybackStatus } from 'components/ui/RecordingControl';

export const useSequenceAudio = (sources: string[]) => {
  const activeAudioIdx = useRef(-1);
  const audioInstance = useRef(new Audio());
  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>('playback_paused');

  const play = async () => {
    try {
      setPlaybackStatus('playback_playing');
      const audioEl = audioInstance.current;

      // Init audio sequence
      if (activeAudioIdx.current === -1) {
        activeAudioIdx.current = 0;

        audioEl.src = sources[activeAudioIdx.current];

        audioEl.onended = () => {
          const nextAudio = sources[activeAudioIdx.current + 1];
          const lastAudio = activeAudioIdx.current === sources.length - 1;

          if (nextAudio) {
            activeAudioIdx.current = activeAudioIdx.current + 1;
            audioEl.src = nextAudio;
            audioEl.load();
            audioEl.play();
          }

          if (lastAudio) {
            setPlaybackStatus('playback_paused');
            activeAudioIdx.current = -1;
          }
        };

        audioEl.load();
      }

      await audioEl.play();
    } catch (error: any) {
      console.error(error?.message ?? error);
    }
  };

  const pause = () => {
    audioInstance.current.pause();
    setPlaybackStatus('playback_paused');
  };

  const stop = () => {
    pause();
    audioInstance.current.currentTime = 0;
  };

  const toggle = () => {
    if (playbackStatus === 'playback_paused') return play();
    if (playbackStatus === 'playback_playing') return pause();
  };

  return {
    playbackStatus,
    play,
    pause,
    stop,
    toggle,
  };
};

import { useMutation } from '@tanstack/react-query';
import snakeCaseKeys from 'snakecase-keys';

import { apiGoLiveClient } from 'lib/apiClient';
import { getAssignmentInfoFromStorage } from 'lib/utils';

import { PostInterestsPayload } from 'types';

const postInterests = async (payload: PostInterestsPayload) => {
  const { userId } = getAssignmentInfoFromStorage();
  const res = await apiGoLiveClient.post(
    `/users/${userId}/interests/6515dc222833185b65314290`,
    snakeCaseKeys(payload, { deep: true })
  );
  return res.data;
};

const usePostInterests = () => useMutation(postInterests);

export default usePostInterests;
